import React from "react";
import EtherPay from "../assets/portfolio/EtherPay.png" ;
import Google from "../assets/portfolio/google drive dapp.png";
import Voting from "../assets/portfolio/Voting dapp.png";
import Lottery from "../assets/portfolio/Lotterydapp.png";
import Charity from "../assets/portfolio/charitableTrust.png";
import Polygon from "../assets/portfolio/Polygondao.png";
import Lifebank from "../assets/portfolio/lifebank.jpg"
import Balancecrypto from "../assets/portfolio/balance-crypto.png"
const Portfolio = () => {
  const portfolios = [
    {
      id: 1,
      src: Charity,
      name: "Ethereum-Solidity-inline-Assembly",
      link: "https://github.com/dalmatian315/Ethereum-Solidity-inline-Assembly"
    },
    {
      id: 2,
      src: Polygon,
      name: "Voting-on-Polygon",
      link: "https://github.com/dalmatian315/blockchain-voting-dapp"
    },
    {
      id: 3,
      src: EtherPay,
      name:"Image-Chain",
      link: "https://github.com/dalmatian315/Image-Chain-Blockchain-Project"
    },
    {
      id: 4,
      src: Voting,
      name: "Crowdfunding-Based-blockchain",
      link: "https://github.com/dalmatian315/Crowdfunding-blockchain-Project"
    },
    {
      id: 5,
      src: Lottery,
      name: "Lottery Dapp",
      link: "https://github.com/dalmatian315/Lottery-Dapp"
    },
    {
      id: 6,
      src: Google,
      name: "NFT Marketplace",
      link: "https://github.com/dalmatian315/nft-marketplace"
    },
    {
      id: 7,
      src: Lifebank,
      name: "Life Bank",
      link: "https://github.com/dalmatian315/lifebank"
    },
    {
      id: 8,
      src: Balancecrypto,
      name: "Balance Crypto",
      link: "https://github.com/dalmatian315/balance-crypto"
    },
  ];
  const handleCodeClick = (link) => {
    window.open(link, '_blank');
    
  };

  return (
    <div
      name="portfolio"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl  font-bold inline border-b-4 border-gray-500">
            Portfolio
          </p>
          <p className="py-6">Check out some of my work right here</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-6 px-12 sm:px-0">
          {portfolios.map(({ id, src,name, link}) => (
            <div key={id} className="shadow-md shadow-gray-600 rounded-lg ">
              <img
                src={src}
                alt=""
                className="rounded-md duration-200 hover:scale-105"
              />  <h1 className="flex items-center justify-center mt-5 font-signature font-bold text-2xl duration-200 hover:scale-105">{name}</h1>
              <div className="flex items-center justify-center">
                <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105" onClick={() => handleCodeClick(link)}>
                  Link
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
